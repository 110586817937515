<template>
  <div id="information">
    <div class="top"></div>
    <div class="news wrapper">
      <div class="title">
        <router-link to="/dash">首页<span v-html="'&nbsp'"></span></router-link>
        <router-link to="/help"
          >-<span v-html="'&nbsp'"></span>帮助<span v-html="'&nbsp'"></span
        ></router-link>
        <a href="javascript:void(0)"
          >-<span v-html="'&nbsp'"></span>租房也可变Dream House</a
        >
      </div>
      <div class="content">
        <el-row>
          <el-col :span="18" class="left">
            <div class="news_title">
              <div class="newsText">租房也可变Dream House</div>
            </div>
            <div class="news_content">
              <p>
                要租一间自己满意的房真的很难，你想自己花钱改造一下，房东连打颗钉子都不行？今天我们就来聊聊那些租房人的最爱，不敲砖不打洞，也能把租房变成自己温馨的Dream
                house。
              </p>
              <p>
                租过房的人都知道，找房子是一件会让你心理落差很大的事情。很多时候在网上看到很漂亮很温馨的图片，你迫不及待地联系上房屋中介约看房时间，生怕好房被人抢走了，结果满怀希望去到那里打开门，看到的竟是这般情景。
              </p>
              <p>
                简陋的“租房三件套”可以把的希望浇熄得一干二净！其实要吧简陋的租房变得有人情味，一点都不难。小编教你把租房变成Dream
                House！
              </p>
              <p>——未来或将严防金融资金炒房</p>
              <p>
                “房子是用来住的，不是用来炒的”，在部署2017年中国房地产市场工作时，中央经济工作会议率先明确了这一战略性定位。
              </p>
              <p class="bold">功能区划分</p>
              <p>
                如果你租的房子已经明确地给你划分了卧室、工作区、客厅、厨房，那就最好不过了！但是对于普通的打工一族，像在北京、深圳这些房价贵得要上天的城市，4K多也只能租个大一点的单间，客厅什么的就别想了！
                在有限的空间范围内实现功能分区很重要！
              </p>
              <p>
                最简单的分区，就是工作的地方和睡觉的地方要分开，或者说吃饭和睡觉的地方要分开。而最简单的分区办法就是买一张足够大，既可以办公，又可以一个人吃饭的桌桌子，摆放在房间合适的角落。
              </p>
              <p>
                如果你是个喜欢小盆栽的文艺青年，还可以在桌上摆放自己喜欢的小玩意。加上小台灯，是不是有静静地看会书的欲望？
              </p>
              <p class="bold">学生台式电脑桌子办公桌写字台组合书桌</p>
              <p>
                这样，一个让人看了就有欲望专心工作的工作区就简单地划分出来了。
              </p>
              <p>
                当然，如果你是个创作型的设计师，一张小小的桌子一定是远远不能满足你的。小编还有一个更省地方的办法，就是把墙直接变成你的创意地。那就是白板（或者是黑板）墙纸了。你可以把它黏贴在墙面，门等任何地方，可以反复使用，写完字用板擦或者湿布擦拭又可以重新用了。
              </p>
              <p>
                现在市面很多这种墙纸可以反复黏贴而不污损被粘物，不伤墙。就是说，你用完可以不留痕迹地撕下来，完全不用怕房东骂咯～
              </p>
              <p class="bold">白板笔教学用白板贴纸</p>
              <p>
                你还可以把它剪裁成其他图形，例如给抽屉做个标签标记抽屉里面放的是什么东西，给鞋盒做个标签，这样取鞋多方便！
              </p>
              <p class="bold">丰富家里的颜色</p>
              <p>
                白色的墙容易让人疲惫，特别是在日光灯的照射下，会显得特别冰冷，缺乏温馨感。如果你家房东让你自己花钱粉刷一下墙壁，那就是最好不过了！
              </p>
              <p>
                建议墙面的颜色以亮丽、青春的为好，比如天蓝色、淡红色等，显得朝气蓬勃一些；如果家具的颜色都是比较淡雅清新的，浅绿、浅黄等，墙面的颜色也要搭配起来，一般的也要选择浅颜色的，比如橙色等，这样看起来比较顺眼一些。
              </p>
              <p>
                如果你家房东不允许你动她矜贵的房子，那你还可以选择墙纸。现在市面的自粘性墙纸用起来很方便，无需刷胶，可以直接粘贴，而且图案和色彩的选择都很多，可以随你自己喜欢。这种墙纸弄起来功夫会比粉刷要少很多，小编也是比较推荐的。
              </p>
              <p>
                另外一个丰富家里颜色的办法就是通过光照来实现了。一盏柔和的床头哦灯，或者一盏温馨的落地灯，可以改变整个房子的主调。
              </p>
              <p>
                挑选的时候，要尽量先考虑灯的放置地方和可以放置位置的大小，不然很小的床头柜上还放一个大的灯，就很浪费了一个放东西的柜子了。所以，其实落地灯是个不错的选择，他可以随时改变放置的位置，用起来比较灵活。
              </p>
              <p class="bold">多增添收纳空间</p>
              <p>
                谁都想租到又大有便宜的房子，但是房价总是很残酷的。所以租房尝尝会遇到“总觉得地方不够用”的困惑。这个时候就要学会收纳归类，还有利用一些家具来发展更多的收纳空间。
              </p>
              <p>
                例如架子。如果平面的空间的不能开发，那就开发你房子的垂直空间！一个1米2左右架子，就可以把平时煮面的锅、煲水的水壶、常用的调味料，甚至烤箱、微波炉都收纳在占地0.6平米不到的地方，这样你就可以为你的书架等等的家具腾出更多的空间了。
              </p>
              <p>
                同样道理，如果你有个小小的厨房，平时看到想要的烤箱、质量很好的锅具想买，但是愁苦没有地方放的话，先想想办法开发厨房的垂直空间。你会发现，你家的厨房也并没有想象中那么小而已。
              </p>
              <p>
                说到收纳，不得不说的还有咱们菇凉们的化妆品，化妆包是一个很好的收纳，但是用起来不够方便，有时候翻翻翻，翻半天都没有找到喜欢的那支眉笔真的很毁心情。所以化妆品需要适当的归类收纳，同时要用起来方便才可以。
              </p>
              <p>
                有些心灵手巧的网友利用废旧的鞋盒来做桌面的收纳，那是很赞的一个方式。但如果你是个不善于做手工的女汉子，那就乖乖求助现成的收纳盒吧！
              </p>
            </div>
          </el-col>
          <el-col :span="5" class="right">
            <div class="recommendTitle">推荐</div>
            <div class="recommendList">
              <div class="recommendItem">
                <router-link to="/news1">
                  <img
                    src="../../assets/images/information/news1.jpeg"
                    alt=""
                  />
                  <div class="msg">
                    住房租赁市场回暖 监管强化助力住房租赁市场规范发展
                  </div>
                </router-link>
              </div>
              <div class="recommendItem">
                <router-link to="/news2">
                  <img
                    src="../../assets/images/information/news2.jpeg"
                    alt=""
                  />
                  <div class="msg">
                    六省市整治住房租赁市场 住房租赁立法亟须按下“快进键”
                  </div>
                </router-link>
              </div>
              <div class="recommendItem">
                <router-link to="/news3">
                  <img src="../../assets/images/information/new3.jpeg" alt="" />
                  <div class="msg">房屋租赁经纪行业制度</div>
                </router-link>
              </div>
              <div class="recommendItem">
                <router-link to="/news4">
                  <img src="../../assets/images/information/new4.jpeg" alt="" />
                  <div class="msg">中央加快住房租赁市场立法</div>
                </router-link>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="less" scoped>
#information {
  .top {
    position: relative;
    top: 0;
    left: 0;
    height: 460px;
    width: 100%;
    background-image: url(../../assets/images/help/帮助背景.png);
    background-size: cover;
  }
  .news {
    .title {
      padding: 12px 0;
      margin-top: 20px;
      text-align: left;
      color: rgba(255, 255, 255, 0.7);
      border-bottom: 1px solid #d2d2d2;
      a {
        color: #5a5e66;
      }
    }
    .content {
      padding-bottom: 50px;
      .left {
        margin-top: 25px;
        .news_title {
          color: #000;
          overflow: hidden;
        }
        .newsText {
          height: 55px;
          line-height: 55px;
          text-align: center;
          font-size: 20px;
          background-color: #f1f3f6;
        }
        .news_content {
          text-align: left;
          color: #000;
          .bold {
            font-weight: 700;
            font-size: 18px;
          }
          p {
            font-size: 16px;
            font-weight: 300;
            color: rgb(51, 51, 51);
            text-align: justify;
            line-height: 24px;
          }
        }
      }
      .right {
        margin: 25px 0 0 50px;
        img {
          width: 200px;
          height: 115px;
        }
        background: #f2f6fa;
        min-height: 300px;
        .recommendTitle {
          color: #000;
          text-align: left;
          font-weight: bold;
          padding: 0 10px;
          margin: 20px 0;
          border-left: 3px solid #3b6798;
        }
        .recommendList {
          padding: 0 0 10px;
          .recommendItem {
            padding: 0 10%;
            margin-bottom: 15px;
            .msg {
              color: #717171;
              margin-top: 5px;
            }
          }
        }
      }
    }
  }
}
</style>
